import React from 'react'

function LisaIsDumb() {
    return (
        <div className='title' style={{textAlign: 'center'}}>
            <div style={{height: 100}}></div>
            Lisa is dumb haha
        </div>
    )
}

export default LisaIsDumb
